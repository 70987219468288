import custOfetch from '~~/composables/useFetch'
import type { Announcement } from '~/types/prisma'

const config = useRuntimeConfig()

export const useAnnouncements = () => {
  const getAll = async () => {
    return await custOfetch(`${config.public.apiBase}/announcements`, {
      method: 'GET',
    }) as Announcement[]
  }

  const getDrafts = async () => {
    return await custOfetch(`${config.public.apiBase}/announcements/drafts`, {
      method: 'GET',
      credentials: 'include',
    }) as Announcement[]
  }

  const create = async (
    data: any,
  ) => {
    return await custOfetch(`${config.public.apiBase}/announcements`, {
      method: 'POST',
      body: data,
      credentials: 'include',
    })
  }
  const update = async (
    data: any,
  ) => {
    return await custOfetch(`${config.public.apiBase}/announcements`, {
      method: 'PATCH',
      body: data,
      credentials: 'include',
    })
  }
  const deleteAnnouncement = async (
    id: string,
  ) => {
    return await custOfetch(`${config.public.apiBase}/announcements/${id}`, {
      method: 'DELETE',
      credentials: 'include',
    })
  }

  const sendAnnouncementById = async (
    id: string,
  ) => {
    return await custOfetch(`${config.public.apiBase}/announcements/${id}/send`, {
      method: 'POST',
      credentials: 'include',
    })
  }

  return {
    getAll,
    getDrafts,
    create,
    deleteAnnouncement,
    update,
    sendAnnouncementById,
  }
}
